import React from 'react';
import Blog1 from '../../assets/blog1.png';
import Blog2 from '../../assets/blog2.png';
import Blog3 from '../../assets/blog3.png';

const BlogCard = ({ image, title, description }) => {
  return (
    <div className="flex flex-col md:flex-row gap-6 md:gap-4 lg:gap-10 py-8 border-b border-gray-300">
      {/* Image Section */}
      <div className="md:w-1/3 w-full">
        <img
          src={image}
          alt={title}
          className="w-full h-48 md:h-auto object-cover rounded-lg"
        />
      </div>
      
      {/* Text Section */}
      <div className="md:w-2/3 w-full flex flex-col justify-between">
        <div>
          <p className="text-xs text-gray-500 uppercase mb-2">
            Posted by | Admin | Foodie
          </p>
          <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">
            {title}
          </h2>
          <p className="text-gray-700 text-sm md:text-base mb-4">
            {description}
          </p>
        </div>
        <button className="bg-yellow-500 hover:bg-yellow-600 text-white w-28 font-semibold text-sm py-2 px-4 rounded transition duration-200">
          Read More
        </button>
      </div>
    </div>
  );
};

const BlogSection = () => {
  const blogPosts = [
    {
      image: Blog1,
      title: 'Behind the Scenes: A Day in the Life of Our Culinary Team',
      description:
        'Ever wondered what goes on behind the kitchen doors? Join us as we take you behind the scenes for an exclusive peek into the daily hustle and bustle of our culinary team...',
    },
    {
      image: Blog2,
      title: 'Exploring Seasonal Delights Through Our Spring Menu',
      description:
        'Spring has sprung, and so has our new seasonal menu! Join us on a culinary journey as we explore the fresh flavors and vibrant ingredients of spring...',
    },
    {
      image: Blog3,
      title: 'The Art of Food Pairing: Elevating Your Dining Experience',
      description:
        'Looking to elevate your dining experience? Join us as we delve into the art of food pairing and discover the magic of flavor combinations...',
    },
  ];

  return (
    <div className="container mx-auto px-4 lg:px-8">
      {blogPosts.map((post, index) => (
        <BlogCard
          key={index}
          image={post.image}
          title={post.title}
          description={post.description}
        />
      ))}
    </div>
  );
};

export default BlogSection;
