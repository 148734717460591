import React from 'react';

const Highlight = () => {
  return (
    <section className="py-12 px-4 md:px-8 lg:px-16 text-center border-l-4 ">
      <h1 className="text-4xl font-bold text-black  underline-offset-4">
        Seasoned By Sey Mour Catering:
      </h1>
      <h2 className="text-2xl font-semibold text-black mt-2  underline-offset-4">
        “A Culinary Extravaganza For Your Senses!”
      </h2>
      
      <p className="mt-8 text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
        Welcome to the flavorful realm of Seasoned By Sey Mour Catering! Step into my world of culinary wonders, 
        where each dish is a tantalizing masterpiece bound to leave you craving more.
      </p>
      
      <p className="mt-4 text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
        From timeless classics to innovative creations, my menu offers something to delight every palate. With a passion 
        for culinary exploration, I continuously craft new and exciting dishes to satisfy your cravings.
      </p>
      
      <p className="mt-4 text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
        Whether you're seeking a private chef in New York for your next event or simply seeking inspiration for your 
        next meal, consider me your culinary guide.
      </p>
    </section>
  );
};

export default Highlight;
