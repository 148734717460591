// EventHero.js
import React from "react";
import BackgroundImage from "../../assets/even.png";

const EventChat = () => {
  return (
    <div
      className="relative h-[400px] bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      <div className="absolute inset-0 flex flex-col items-center justify-center px-4 text-center">
        <h1 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-bold leading-tight font-lato">
          Kickstart Your Private Event With <br /> Seasoned By Mour Catering
        </h1>

        <p className="my-3 text-lg sm:text-xl md:text-2xl lg:text-3xl font-lato">
          Talk To Our Professional Private Dining Team To Start Planning For
          Your Event
        </p>

        <button className="mt-6 px-6 py-3 border-2 border-white text-white text-lg font-semibold hover:bg-white hover:text-black transition duration-300">
          Chat With Us
        </button>
      </div>
    </div>
  );
};

export default EventChat;
