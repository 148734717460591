import React from 'react'
import FAQ from '../../components/faq/faq'
import Footer from '../../components/Footer/Footer'
import MenuHero from '../../components/HeroSection/MenuHero'
import SubscriptionSection from '../../components/subscription/Subscription'
import TestimonialsSection from '../../components/Testimonial/TestimonialSection'
import MenuCourse from '../../components/MenuSection/MenuCourse'


const Menu = () => {
  return (
    <div>
        <MenuHero/>
        <MenuCourse/>
        <TestimonialsSection/>
        <FAQ/>
        <SubscriptionSection/>
        <Footer/>
    </div>
  )
}

export default Menu