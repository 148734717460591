// HeroSection.js
import React from "react";
import BackgroundImage from "../../assets/abg.png";
import Image1 from "../../assets/abt1.png"; // Replace with actual paths to your images
import Image2 from "../../assets/abt2.png";
import Image3 from "../../assets/abt3.png";

const AboutHero = () => {
  return (
    <div
      className="relative h-[100vh] bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      <div className="absolute inset-0 flex flex-col items-center justify-center px-4 text-center">
        {/* Title */}
        <h1
          className="text-white uppercase"
          style={{
            fontFamily: "Bebas Neue, sans-serif",
          }}
        >
          <span className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-caramel font-bold">
            About
          </span>
        </h1>

        {/* Subtitle */}
        <h2 className="text-yellow-500 text-2xl sm:text-3xl mt-2">
          Our Story
        </h2>

        {/* Main Headline */}
        <h3 className="text-yellow-400 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold mt-4 max-w-4xl">
          DISCOVER EXCEPTIONAL FLAVORS: EXPERIENCE LUXURY DINING AT ITS FINEST
        </h3>

        {/* Description */}
        <p className="text-white text-base sm:text-lg md:text-xl mt-4 max-w-2xl mx-auto">
          Welcome to Seasoned By Sey Mour, located in the heart of Atlanta. Our
          restaurant offers a dining experience unlike any other. From expertly
          crafted dishes to an ambiance of refined elegance.
        </p>

        {/* Image Gallery */}
        <div className="flex mt-8 space-x-4 md:space-x-6 lg:space-x-8">
          {/* Each image with styling */}
          <img
            src={Image1}
            alt="Dish 1"
            className="w-24 h-24 sm:w-32 sm:h-32 md:w-48 md:h-48 border-1 border-white rounded-lg"
          />
          <img
            src={Image2}
            alt="Dish 2"
            className="w-44 h-44 sm:w-32 sm:h-32 md:w-56 md:h-56 border-1 border-white rounded-lg"
          />
          <img
            src={Image3}
            alt="Dish 3"
            className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 border-1 border-white rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
