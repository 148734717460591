import React from 'react'
import FAQ from '../../components/faq/faq'
import Footer from '../../components/Footer/Footer'
import AboutHero from '../../components/HeroSection/AboutHero'
import SubscriptionSection from '../../components/subscription/Subscription'
import TestimonialsSection from '../../components/Testimonial/TestimonialSection'
import Services from '../../components/About/Sevices'
import ChefProfile from '../../components/About/ChefProfile'


const About = () => {
  return (
    <div>
        <AboutHero/>
        <Services/>
        <ChefProfile />
        <TestimonialsSection/>
        <FAQ/>
        <SubscriptionSection/>
        <Footer/>
    </div>
  )
}

export default About