import React from 'react'
import FAQ from '../../components/faq/faq'
import Footer from '../../components/Footer/Footer'
import ContactHero from '../../components/HeroSection/ContactHero'
import SubscriptionSection from '../../components/subscription/Subscription'
import TestimonialsSection from '../../components/Testimonial/TestimonialSection'
import ContactForm from '../../components/Contact/ContactForm'
import WhereToFindUs from '../../components/Contact/WhereToFindUs'
import Socials from '../../components/Contact/Socials'
import Location from '../../components/Contact/Location'


const Contact = () => {
  return (
    <div>
        <ContactHero/>
        <WhereToFindUs/>
        <Socials/>
        <Location/>
        <ContactForm/>
        <TestimonialsSection/>
        <FAQ/>
        <SubscriptionSection/>
        <Footer/>
    </div>
  )
}

export default Contact