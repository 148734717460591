import React from "react";
import Image4 from "../../assets/test4.png";

const EventHighlights = () => {
  return (
    <section className="py-12 text-center my-10">
      <h2 className="text-2xl font-semibold mb-8">⭐ Event Highlights ⭐</h2>
      <div className="relative max-w-3xl mx-auto">
        <img
          src={Image4}
          alt="Event Highlight"
          className="w-full h-auto rounded-lg shadow-lg"
        />
        <div className="absolute bg-amber-300 bg-opacity-50 flex flex-col bottom-2 right-0 w-[400px] text-white p-6 rounded-lg">
          <h3 className="text-lg font-bold text-center">Wedding Extravaganza</h3>
          <p className="text-sm text-center">
            A Fairy-Tale Wedding Brought To Life. Every Detail Was Carefully
            Curated For A Truly Magical Celebration.
          </p>
        </div>
      </div>
    </section>
  );
};

export default EventHighlights;
