import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import Logo from "../../assets/logo1.png";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8 px-4 md:px-16">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-5 gap-8">
        {/* Logo Section */}
        <div className="border-yellow-500">
          <img
            src={Logo}
            alt="Logo"
            width={70}
            height={70}
            className="rounded-full p-0"
          />
        </div>

        {/* Welcome Section */}
        <div>
          <h2 className="text-lg font-semibold font-lato mb-3">WELCOME</h2>
          <ul className="space-y-1">
            <li>
              <Link to="/menu" className="hover:underline font-lato">
                Menu
              </Link>
            </li>
            <li>
              <Link to="/event" className="hover:underline font-lato">
                Event
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:underline font-lato">
                About
              </Link>
            </li>
          </ul>
        </div>

        {/* Explore Section */}
        <div>
          <h2 className="text-lg font-semibold font-lato mb-3">EXPLORE</h2>
          <ul className="space-y-1">
            <li>
              <Link to="/blog" className="hover:underline font-lato">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/gallery" className="hover:underline font-lato">
                Gallery
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline font-lato">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* Other Section */}
        <div>
          <h2 className="text-lg font-semibold font-lato mb-3">OTHER</h2>
          <ul className="space-y-1">
            <li>
              <Link to="/privacy" className="hover:underline font-lato">
                Privacy
              </Link>
            </li>
            <li>
              <Link to="/policy" className="hover:underline font-lato">
                Policy
              </Link>
            </li>
          </ul>
        </div>

        {/* Social Media Section */}
        <div>
          <h2 className="text-lg font-semibold">FOLLOW</h2>
          <div className="flex space-x-4 mt-2">
            <a
              href="https://www.facebook.com"
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400"
            >
              <Facebook fontSize="small" />
            </a>
            <a
              href="https://www.instagram.com"
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400"
            >
              <Instagram fontSize="small" />
            </a>
            <a
              href="https://www.youtube.com"
              aria-label="YouTube"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-400"
            >
              <YouTube fontSize="small" />
            </a>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="mt-20 text-sm font-lato text-center">
        <p>
          <em>&copy; 2024 Seasoned by Seymour Catering</em>
          <span className="mx-2">|</span>
          <em>
            <a
              href="http://www.tywebstudio.com"
              className="hover:text-yellow-500 transition"
              target="_blank"
              rel="noopener noreferrer"
            >
              Design by Tywebstudio
            </a>
          </em>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
