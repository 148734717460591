import React from 'react'
import Footer from '../../components/Footer/Footer'
import BlogHero from '../../components/HeroSection/BlogHero'
import BlogSection from '../../components/BlogSection/BlogSection'
import ContactForm from '../../components/Contact/ContactForm'


const Blog = () => {
  return (
    <div>
        <BlogHero/>
        <BlogSection/>
        
        <ContactForm/>
        <Footer/>
    </div>
  )
}

export default Blog