import React from 'react';

const WhereToFindUs = () => {
  return (
    <section className="text-center py-8 my-12">
      <h2 className="text-2xl font-bold mb-14 font-lato">Where To Find Us</h2>
      <div className="flex flex-col md:flex-row gap-10 md:justify-between max-w-4xl mx-auto space-y-6 md:space-y-0 px-4">
        
        {/* Contact Info Section */}
        <div className="text-left">
          <h3 className="text-lg font-semibold font-lato">CONTACT INFO</h3>
          <p className="mt-2 text-gray-700 font-lato">
            At Seasoned By Sey Mour Catering, we blend unique flavors with a touch of aristocratic style, creating unforgettable dining experiences for all. Join us in exploring the world of delicious food and delightful surprises.
          </p>
        </div>

        {/* Address Section */}
        <div className="text-left">
          <h3 className="text-lg font-semibold font-sans ">SEASONED BY SEY MOUR CATERING</h3>
          <p className="mt-2">
            <span className="font-semibold font-lato">Address:</span> 3090 Branham Drive, Atlanta, GA 30360
          </p>
          <p>
            <span className="font-semibold font-lato">Phone:</span> 770-815-2881
          </p>
          <p>
            <span className="font-semibold font-lato">Email:</span> seasonedbyseymourcatering.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhereToFindUs;
