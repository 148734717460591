import React from 'react'
import Footer from '../../components/Footer/Footer'
import EventHero from '../../components/HeroSection/EventHero'
import EventGallery from '../../components/EventHighlight/EventGallery'
import EventChat from '../../components/EventHighlight/EventChat'


const Event = () => {
  return (
    <div>
        <EventHero/>
        <EventGallery/>
        <EventChat/>
        <Footer/>
    </div>
  )
}

export default Event