import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../assets/logo1.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLinkClick = () => {
    
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setDrawerOpen(false); 
      }
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <AppBar
        position="static"
        className="shadow-none px-4 md:px-16"
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        elevation={0}
      >
        <Toolbar className="flex justify-between items-center px-4 md:px-16 py-3">
          <div className="hidden md:flex space-x-8 md:space-x-20">
            <Link to="/menu" onClick={handleLinkClick} className="text-white hover:text-yellow-500 transition-colors">
              Menu
            </Link>
            <Link to="/events" onClick={handleLinkClick} className="text-white hover:text-yellow-500 transition-colors">
              Events
            </Link>
            <Link to="/about" onClick={handleLinkClick} className="text-white hover:text-yellow-500 transition-colors">
              About
            </Link>
          </div>

        
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center justify-center">
              <div className="rounded-full border-4 border-yellow-500 p-1">
                <img
                  src={Logo}
                  alt="Logo"
                  className="rounded-full"
                  style={{
                    width: "30px", 
                    height: "30px",
                    maxWidth: "40px", 
                    maxHeight: "40px",
                  }}
                />
              </div>
            </Link>
          </div>

          <div className="hidden md:flex space-x-8 md:space-x-20">
            <Link to="/blog" onClick={handleLinkClick} className="text-white hover:text-yellow-500 transition-colors">
              Blog
            </Link>
            <Link to="/gallery" onClick={handleLinkClick} className="text-white hover:text-yellow-500 transition-colors">
              Gallery
            </Link>
            <Link to="/contact" onClick={handleLinkClick} className="text-white hover:text-yellow-500 transition-colors">
              Contact
            </Link>
          </div>

          <div className="md:hidden">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon className="text-yellow-500" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left" 
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%", 
            backgroundColor: "rgba(0, 0, 0, 0.7)", 
            color: "#FFFFFF",
          },
        }}
      >
        <div className="flex justify-between items-center p-4">
          <img src={Logo} alt="Logo" className="w-10 h-10 rounded-full border-4 border-yellow-500" />
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon className="text-yellow-500" />
          </IconButton>
        </div>

        <List className="space-y-4 text-center">
          {["Menu", "Events", "About", "Blog", "Gallery", "Contact"].map((item) => (
            <ListItem button key={item} onClick={toggleDrawer(false)}>
              <ListItemText>
                <Link to={`/${item.toLowerCase()}`} className="text-white w-full text-left">
                  {item}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
          <ListItem className="flex justify-center mt-6">
            <Button
              variant="contained"
              onClick={() => alert("Order Now clicked!")}
              sx={{
                backgroundColor: "#EAB308",
                color: "white",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#D4A106",
                },
              }}
            >
              Order Now
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
