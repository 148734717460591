import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { database } from '../../firebaseConfig'; 
import { ref, push, set } from "firebase/database"; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const contactRef = push(ref(database, 'contacts')); 
      await set(contactRef, {
        ...formData,
        createdAt: new Date().toISOString() 
      });
      
      toast.success("Message sent successfully!");
      setFormData({ name: "", email: "", phone: "", message: "" }); 
    } catch (error) {
      console.error("Error sending message: ", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <section className="flex flex-col items-center justify-center py-12 px-4">
      <h2 className="text-2xl font-bold mb-8">Get In Touch</h2>

      <form onSubmit={handleSubmit} className="w-full max-w-md flex flex-col space-y-4">
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded bg-gray-100 w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          required
        />

        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded bg-gray-100 w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          required
        />

        <input
          type="tel"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded bg-gray-100 w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
        />

        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          className="p-4 border border-gray-300 rounded bg-gray-100 w-full h-32 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          required
        ></textarea>

        <button
          type="submit"
          className="mt-6 py-3 bg-yellow-500 text-white font-semibold rounded hover:bg-yellow-600 transition"
        >
          Send
        </button>
      </form>
    </section>
  );
};

export default ContactForm;
