import React from 'react';

const MenuSection = ({ title, items }) => (
  <div className="rounded-md bg-[#443A17] text-[#E6C82B] my-8 p-4 shadow-lg relative ">
    {/* Section Title */}
    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#E6C82B] text-[#443A17] px-6 py-1 font-bold rounded-full border-2 border-[#443A17]">
      {title}
    </div>
    {/* Menu Items */}
    <ul className="mt-8 space-y-1">
      {items.map((item, index) => (
        <li key={index} className="border-b border-[#E6C82B] pb-2">
          {item}
        </li>
      ))}
    </ul>
  </div>
);


const MenuCourse = () => {
  const menuSections = [
    {
      title: "Main Course",
      items: [
        "Turkey Wraps", "Portobello Wraps", "Smoked Chipotle Grilled Chicken Wraps", 
        "Soft Shell Tacos Chicken", "Shrimps Or Fish Beyond Meat Tacos (Vegan)",
        "Perk Chicken Pasta", "Salmon Pasta", "Caribbean Style Rasta Pasta (Vegan)", 
        "Fried Chicken", "Blackened Fish"
      ],
    },
    {
      title: "Sides",
      items: [
        "Old Fashioned Green Beans", "Black Beans", "Cilantro Rice", 
        "Cinnamon Churros", "Fresh Fruit Bowl", "Garlic Bread", 
        "Mac And Cheese", "Cheese Grits", "Cornbread", "Potato Chips"
      ],
    },
    {
      title: "Salads",
      items: [
        "Potato Salad", "Fresh Fruit Salad", "Kale Salad", 
        "Tuna Salad", "Smoked Chicken Salad"
      ],
    },
  ];

  return (
    <div className="text-center px-4 sm:px-8 lg:px-16 py-10">
      <h2 className="text-xl sm:text-2xl font-semibold mb-12 text-gray-800">
        Browse Our Menu Or Let Us Create Something For You
      </h2>
      <div className="max-w-3xl mx-auto">
        {menuSections.map((section, index) => (
          <MenuSection key={index} title={section.title} items={section.items} />
        ))}
      </div>
    </div>
  );
};

export default MenuCourse;
