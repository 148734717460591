import React from "react";

const services = [
  "FINE DINING",
  "OUTDOOR CATERING",
  "DOOR DELIVERY",
  "BANQUET",
];

const Services = () => {
  return (
    <section className="bg-white py-4 flex h-24 flex-wrap justify-center border-4 border-black items-center space-x-4 text-center text-lg font-semibold mt-8">
      {services.map((service, index) => (
        <div key={index} className="text-black mx-4 flex items-center">
          {service}
          {index < services.length - 1 && <span className="text-yellow-500 mx-2">★</span>}
        </div>
      ))}
    </section>
  );
};

export default Services;
