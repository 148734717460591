import React from 'react';

const Location = () => {
  return (
    <section className="text-center py-8">
      <h2 className="text-2xl font-bold mb-4">Location</h2>
      <div className="flex justify-center">
        <iframe
          title="location-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26447.724210191615!2d-84.40019391158674!3d33.74902770830344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045625e67f5b%3A0x921d8a7934725e5c!2sAtlanta%2C%20GA!5e0!3m2!1sen!2sus!4v1608284690284!5m2!1sen!2sus"
          width="100%"
          height="300"
          className="max-w-4xl border rounded"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export default Location;
