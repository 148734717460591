import React from 'react'
import FAQ from '../../components/faq/faq'
import Footer from '../../components/Footer/Footer'
import GalleryHero from '../../components/HeroSection/GalleryHero'
import SubscriptionSection from '../../components/subscription/Subscription'
import TestimonialsSection from '../../components/Testimonial/TestimonialSection'
import GalleryGrid from '../../components/Gallery/GalleryGrid'


const Gallery = () => {
  return (
    <div>
        <GalleryHero/>
        <GalleryGrid/>
        <TestimonialsSection/>
        <FAQ/>
        <SubscriptionSection/>
        <Footer/>
    </div>
  )
}

export default Gallery