import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300 py-4">
      <div 
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold text-black hover:underline">{question}</h3>
        <span className="text-2xl">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: 'What Types Of Events Does Seasoned By Sey Mour Catering Cater To?',
      answer: 'Seasoned By Sey Mour Catering caters to a wide range of events including weddings, corporate events, private dinners, and more. We customize our offerings to meet the unique needs of each occasion.',
    },
    {
      question: 'How Can I Request Catering Services From Seasoned By Sey Mour?',
      answer: 'To request our catering services, you can contact us directly through our website or give us a call. Our team will work with you to understand your requirements and make your event unforgettable.',
    },
    {
      question: 'Does Seasoned By Sey Mour Offer Menu Customization Options?',
      answer: 'Yes, we offer menu customization options to ensure that your event menu reflects your tastes and preferences. Let us know what you’re looking for, and we’ll craft a menu just for you.',
    },
  ];

  return (
    <section className="py-12 px-4 md:px-8 lg:px-16">
      <div className="flex items-center justify-center mb-8">
        <span className="text-yellow-500 text-xl mr-2">⭐</span>
        <h2 className="text-3xl font-bold">Q & A</h2>
        <span className="text-yellow-500 text-xl ml-2">⭐</span>
      </div>
      
      <div className="max-w-2xl mx-auto">
        {faqs.map((faq, index) => (
          <FAQItem 
            key={index} 
            question={faq.question} 
            answer={faq.answer} 
          />
        ))}
      </div>
    </section>
  );
};

export default FAQ;
