import React from 'react';
import ChefImage from '../../assets/chef.png'; 
import ChefBackground from '../../assets/chefbg.png'; 

const ChefProfile = () => {
  return (
    <div className="flex flex-col md:flex-row gap-10 items-center justify-center py-10 px-4 sm:px-8 lg:px-16">
      <div className="w-full md:w-1/2 flex justify-center md:justify-end mb-8 md:mb-0 relative">
        
  
        <div className="w-3/4 sm:w-2/3 md:w-2/3 lg:w-3/5 relative rounded-t-full overflow-hidden">
          <img
            src={ChefBackground}
            alt="Chef Background"
            className="object-cover w-full h-full"
          />

          <div className="absolute bottom-0 right-0 ">
            <img
              src={ChefImage}
              alt="Chef Sey Mour"
              className="object-cover w-[350px] h-[350px]"
            />
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 text-center md:text-left px-4">
        <h4 className="text-yellow-600 font-semibold mb-2">Our Chef</h4>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 font-lato">
          A MASTER CHEF WITH 10 YEARS OF EXPERIENCE
        </h2>
        <p className="text-gray-700 leading-9 mb-4 font-lato ">
          Get To Know The Genius Behind Our Delicious Creations: Sey Mour. With
          10 Years Of Experience, Chef Sey Mour Brings Passion And Skill To Every
          Dish. Join Us As We Celebrate Their Talent And Savor The Flavors Of
          Their Culinary Mastery.
        </p>
        <p className="text-lg font-bold font-lato mt-10">MASTER CHEF SEY MOUR</p>
      </div>
    </div>
  );
};

export default ChefProfile;
