import React from 'react';
import { Facebook, Instagram, YouTube } from '@mui/icons-material';
import { FaTiktok } from 'react-icons/fa';

const Socials = () => {
  return (
    <section className="text-center py-8 my-14">
      <h2 className="text-2xl font-bold mb-4">Socials</h2>
      <div className="flex justify-center space-x-6 mt-2">
        <a href="#" aria-label="Facebook" className="text-gray-900 hover:text-gray-600">
          <Facebook fontSize="large" />
        </a>
        <a href="#" aria-label="Instagram" className="text-gray-900 hover:text-gray-600">
          <Instagram fontSize="large" />
        </a>
        <a href="#" aria-label="YouTube" className="text-gray-900 hover:text-gray-600">
          <YouTube fontSize="large" />
        </a>
        <a href="#" aria-label="TikTok" className="text-gray-900 hover:text-gray-600">
          <FaTiktok size={28} />
        </a>
      </div>
    </section>
  );
};

export default Socials;
