import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { database } from '../../firebaseConfig'; 
import { ref, push, set } from "firebase/database";

const SubscribeSection = () => {

  const [formData, setFormData] = useState({
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const contactRef = push(ref(database, 'subscription')); 
      await set(contactRef, {
        ...formData,
        createdAt: new Date().toISOString() 
      });
      
      toast.success("Message sent successfully!");
      setFormData({  email: "" }); 
    } catch (error) {
      console.error("Error sending message: ", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <section className="bg-gray-100 py-12 px-4 sm:px-8 md:px-16 flex flex-col md:flex-row justify-between">
      {/* Left Side: Text Content */}
      <div className="md:w-1/3 mb-8 md:mb-0  md:text-left">
        <h2 className="text-yellow-500 text-2xl sm:text-3xl md:text-4xl font-bold leading-tight font-lato">
          KEEP UP TO DATE WITH SEASONED BY SEYMOUR CATERING
        </h2>
        <p className="mt-4 text-gray-700 text-sm sm:text-base md:text-lg font-lato">
          Be the first to signup and receive special news from seasoned by Sey Mour Catering.
        </p>
      </div>

      {/* Right Side: Subscription Form */}
      <div className="md:w-1/2 flex flex-col items-start gap-3 justify-center w-full">
        {/* Form wrapper */}
        <form onSubmit={handleSubmit} className="w-full">
          <input
            type="email"
            name="email"
            placeholder="Email Address..."
            value={formData.email}
            onChange={handleChange}
            required
            className="p-3 text-sm sm:text-base md:text-lg border border-gray-300 rounded mb-4 sm:mb-0 w-full sm:w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <button
            type="submit"
            className="px-4 py-2 mt-3 sm:px-6 sm:py-3 sm:w-52 border border-gray-900 text-gray-900 text-sm font-lato sm:text-base md:text-lg font-semibold hover:bg-gray-900 hover:text-white transition"
          >
            SUBSCRIBE
          </button>
        </form>
      </div>
    </section>
  );
};

export default SubscribeSection;
