import React from 'react'
import Footer from '../../components/Footer/Footer'
import Hero from '../../components/HeroSection/Hero'
import Highlight from '../../components/Highlight/Hilight'
import IntroSection from '../../components/IntroSection/IntroSection'
import MenuSection from '../../components/MenuSection/MenuSection'
import TestimonialsSection from '../../components/Testimonial/TestimonialSection'
import EventHighlights from '../../components/EventHighlight/EventHighlight'
import LatestBlog from '../../components/latestBlog/LatestBlog'
import FAQ from '../../components/faq/faq'
import SubscriptionSection from '../../components/subscription/Subscription'


const Home = () => {
  return (
    <div>
        <Hero/>
        <Highlight/>
        <IntroSection/>
        <MenuSection/>
        <TestimonialsSection/>
        <EventHighlights/>
        <LatestBlog/>
        <FAQ/>
        <SubscriptionSection/>
        <Footer/>
    </div>
  )
}

export default Home