import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database"; 

const firebaseConfig = {
  apiKey: "AIzaSyCueEuTcKA_063tOh0hRY-E2WYXv6U0c3Q",
  authDomain: "seasonedbysemour.firebaseapp.com",
  databaseURL: "https://seasonedbysemour-default-rtdb.firebaseio.com",
  projectId: "seasonedbysemour",
  storageBucket: "seasonedbysemour.firebasestorage.app",
  messagingSenderId: "28587628310",
  appId: "1:28587628310:web:a2be3126c7c83a4266a237",
  measurementId: "G-MSG0EECQ46"
  
};


const app = initializeApp(firebaseConfig);


const firestore = getFirestore(app);

const database = getDatabase(app); 

export { firestore, database };
