import React from 'react';
import menu1 from "../../assets/resi1.png";
import menu2 from "../../assets/resi2.png";
import menu3 from "../../assets/resi3.png";
import menu4 from "../../assets/resi4.png";
import menu5 from "../../assets/resi5.png";
import menu6 from "../../assets/resi6.png";
const LatestBlog = () => {
  const blogPosts = [
    { title: "Seasonal Recipe Showcase", description: "From comforting winter soups to refreshing summer salads...", image: menu1 },
    { title: "Trendy Food Pairing", description: "Discover the best food pairings for any occasion...", image: menu2 },
    { title: "Event Showcase Tips and Tricks", description: "Learn tips and tricks for a memorable event...", image: menu3 },
    { title: "Spotlight on Local Ingredients", description: "Explore the benefits of sourcing local ingredients...", image: menu4 },
    { title: "Culinary Travel Adventures", description: "A journey through culinary travel experiences...", image: menu5 },
    { title: "A Day in the Life of a Private Chef", description: "Experience a day in the life of a private chef...", image: menu6 },
  ];

  return (
    <section className="py-12 text-left">
      <h2 className="text-2xl font-semibold mb-8 text-center">⭐ Latest Blog ⭐</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
        {blogPosts.map((post, index) => (
          <div key={index} className="relative rounded-lg overflow-hidden shadow-lg">
            <img src={post.image} alt={post.title} className="w-full h-52 object-cover" />
            <div className="absolute inset-0 bg-black bg-opacity-50 p-4 flex flex-col justify-end">
              <div className='flex flex-col items-start w-[300px]'>
              <h3 className="text-lg font-bold text-white w-2/3">{post.title}</h3>
              <p className="text-sm text-white mb-4">{post.description}</p>
              <button className="text-yellow-300 font-semibold border p-2">Read More</button>
              </div>
              
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LatestBlog;
